const Button = ({ children, custom, onClick, disabled, type }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={` px-4 sm:px-6 rounded-sm shadow-sm capitalize transition-all duration-200 ease-in-out ${custom}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
