const HttpErrorCodes = {
  // client error response
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  408: "Request Timeout",

  // server errors
  500: "Internal Server",
};

export default HttpErrorCodes;
