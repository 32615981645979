import ServerContext from "context/ServerContext";
import { useContext, useEffect } from "react";
import Lottie from "react-lottie-player";
import { useLocation, useNavigate } from "react-router-dom";
import { labourDatavar } from "../../apollo/store";
import { CameraProvider } from "../../context/CameraContext";
import power from "../../assets/lotty/power.json";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar.jsx";

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { reconnectDone } = useContext(ServerContext);

  //paths to re-route
  const allowed = [
    "/home",
    "/biometrics-home",
    "/face-setting",
    "/face-scan",
    "/iris-setting",
    "/iris-scan",
    "/fp-setting",
    "/fp-scan",
  ];

  const notAllowedNav = [
    "/face-setting",
    "/face-scan",
    "/iris-setting",
    "/iris-scan",
    "/fp-setting",
    "/fp-scan",
  ];

  useEffect(() => {
    if (!allowed.includes(pathname) || !labourDatavar()) {
      routeToDash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeToDash = () => {
    navigate("/home");
  };

  return (
    <CameraProvider>
      <div className="relative">
        {/* {reconnectDone && (
          <div className="backdrop-blur-[2px] absolute top-0 left-0  bg-white w-full h-full z-50  flex flex-col  items-center justify-center">
            
  <div className="pattern-wavy pattern-blue-500 pattern-bg-white 
  pattern-size-6 pattern-opacity-20"></div>
  
            <Lottie loop animationData={power} play style={{ width: 200 }} />
            <p className="text-md">DISCONNECTED</p>
          </div>
        )} */}

        <div className="bg-blue-200/20 flex space-x-6 p-4 h-screen">
          <div className="h-full">
            <Sidebar />
          </div>
          <div className="w-full space-y-6 relative flex flex-col">
            {!notAllowedNav.includes(pathname) && <NavBar />} {children}
          </div>
        </div>
      </div>
    </CameraProvider>
  );
};

export default AppLayout;
