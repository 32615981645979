import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo/client";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./hooks/modalContext";
import { ServerProvider } from "./context/ServerContext";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ServerProvider>
        <ModalProvider>
          <Toaster position="top-right" />
          <App />
        </ModalProvider>
      </ServerProvider>
    </BrowserRouter>
  </ApolloProvider>
);
